<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="uc_con">

      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>预订人查询</div>
      </div>

      <div class="t_l">
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <el-form-item label="预订人姓名">
            <el-input placeholder="预订人姓名" v-model="searchInfo.reservationPersonName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="searchInfo = {reservationPersonName:''}">重置</el-button>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="uc_con">

      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>预订人列表</div>
        <div class="flex_1 t_r">
          <div class="add_btn cur_p" @click="dialogVisible = true">添加新的预订人</div>
        </div>
      </div>

      <el-table
        ref="multipleTable"
        :data="list"
        stripe
        :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="姓名" align="center">
          <template #default="scope">{{ scope.row.reservationPersonName }}</template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号码" align="center"> </el-table-column>
        <el-table-column label="操作" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div class="d_f ali_c j_c">
              <div class="right_btn cur_p" @click="changeRow(scope.row)">
                <img src="../assets/images/sd_xiugai.png" alt="">
                <span>修改</span>
              </div>
              <div class="right_btn cur_p" @click="deleteRow(scope.row)">
                <img src="../assets/images/sd_shanchu.png" alt="">
                <span>删除</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--addPop-->
    <el-dialog
      v-model="dialogVisible"
      width="40%"
      :show-close="false"
    >
      <template #title>
        <div class="uc_title d_f ali_c flex_1">
          <div class="uc_green"></div>
          <div>添加预订人</div>
          <div class="flex_1 t_r" @click="dialogVisible = false">
            <img class="close" src="../assets/images/bk_close.png" alt="">
          </div>
        </div>
      </template>
      <div class="form_out">
        <el-form  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm">
          <el-form-item label="姓名" prop="reservationPersonName">
            <el-input v-model="ruleForm.reservationPersonName"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>

        <div class="t_a ch_btn">
          <div class="cp_btn" @click="save('ruleForm')">确定</div>
          <div class="cp_btn ac" @click="resetForm('ruleForm')">取消</div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  let checkPhone = (rule, value, callback) => {
    let reg = /^1[345789]\d{9}$/;
    if (!reg.test(value)) {
      callback(new Error('请输入正确的号码'))
    } else {
      callback()
    }
  }
  export default {
    name: "booker",
    data() {
      return {
        dialogVisible:false,
        searchInfo:{reservationPersonName:''},
        list:[],
        ruleForm:{
          phone:'',
          reservationPersonName:'',
        },
        isModify:false,
        rules:{
          reservationPersonName:[
            { required: true, message: '请输入姓名', trigger: 'blur' },
          ],
          phone:[
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
          ]
        }
      }
    },
    created(){
      this.search();
    },
    methods: {
      save(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.isModify){
              this.$api.modify(this.ruleForm).then(res => {
                this.resetForm(formName);
                this.delSuc();
              })
            }else{
              this.$api.addYD(this.ruleForm).then(res => {
                this.resetForm(formName);
                this.delSuc();
              })
            }
          } else {
            return false
          }
        })
      },
      delSuc(){
        this.$message.success('操作成功~');
        this.search();
      },
      resetForm(formName) {
        this.dialogVisible = false;
        this.isModify = false;
        this.$refs[formName].resetFields();
        this.ruleForm = {
          phone:'',
            reservationPersonName:''
        }
      },
      changeRow(row){
        this.isModify = true;
        this.ruleForm = {
          phone:row.phone,
          reservationPersonId:row.reservationPersonId,
          reservationPersonName:row.reservationPersonName,
          version:row.version,
        };
        this.dialogVisible = true;
      },
      deleteRow(row){
        this.$messageBox.confirm(
          '确认删除?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            this.$api.deleteBatch([row.reservationPersonId]).then(res => {
              this.delSuc();
            })
          })
      },
      search(){
        this.$api.searchList(this.searchInfo).then(res => {
          this.list = res.data;
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .form_out{
    padding: 0 132px 0 76px;
  }
  .ch_btn div{
    &.ac{
      margin-left: 70px;
    }
    display: inline-block;
    width: 228px;
    cursor: pointer;
  }
  .right_btn{
    margin-left: 22px;
  }
  .uc_title{
  }
</style>
